import { Container } from "../components/Container";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Index = () => (
  <Container>
    <StaticImage className="mx-auto m-4" src="../images/404.webp" />
  </Container>
);

export default Index;
